<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'manula-eis',
  primary_color: '#FFE1BA',
  secondary_color: '#F18700',
  header: {
    background: {
      image: `/projekt/manula-eis/elbworx-projekt-manula_eis-header-2560.webp`,
      video: `/projekt/manula-eis/elbworx-manula-eis.mp4`,
    },
    project: 'Manula Eis',
    project_class: 'text-elbworx-black',
    headline: 'Wild im Geschmack',
    info_card: {
      description:
        'Super-leckeres Eis + extra-großer Löffel Kreativität = Geschmack-volles Design!',
      client: 'Manula Eis, Prina',
      roles: ['Corporate Design', 'Webdesign', 'Verpackungsdesign'],
      project_duration: '2021 bis 2022',
      project_url: 'https://manula-eis.de/',
      font_black: true,
      custom_button_class: 'bg-[#68C2BF]',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Die Herausforderung</h3>

      <p>
        <i>Wild im Geschmack!</i>
        Selten war Design so lecker, haben wir bei einem Projekt so viel genascht und probiert, wie
        bei der Arbeit für
        <i>Manula Eis</i>
        . Die außergewöhnlichen Sorten nicht nur
        <i>visuell</i>
        greifbar zu machen, sondern auch mit klangvollen
        <i>Namen</i>
        zu versehen, die Lust wecken auf ein paar wirklich wilde Geschmackskombinationen:&#32;darin
        bestand die bis dato wohl süßeste Herausforderung unserer Agentur.
      </p>
      <p>
        Besonders die Präsentation der zahlreichen Sorten — wir tauften sie
        <i>Erdbeerliebe</i>
        ,
        <i>Schokoschock</i>
        oder
        <i>Tiger-Cookie</i>
        — hat uns im kreativen Prozess beflügelt und immer wieder für lustige Momente gesorgt. Neben
        dem Corporate Design (Logo, Schrift, Farben, Illustrationen) sind auch ein leckerer
        Internet-Auftritt sowie ein geschmacksverliebtes Verpackungsdesign entstanden: bunt,
        fröhlich und wild! So wie das Eis von Manuela und Christian — den kreativen Köpfen hinter
        Manula Eis.
      </p>
    </template>

    <Padding>
      <Grid class="items-end">
        <div class="col-span-4">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-manula_eis-image-01.svg`"
          />
        </div>
        <div class="col-span-8 flex items-center justify-center bg-secondary py-16 md:h-[600px]">
          <Image
            class="h-full w-auto"
            :src="`${projectPath}elbworx-projekt-manula_eis-image-02.svg`"
          />
        </div>
      </Grid>
    </Padding>
    <Padding>
      <div>
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-manula_eis-collage-3840.webp`"
        />
      </div>
    </Padding>
    <Padding>
      <Grid class="items-start">
        <div class="col-span-8">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-manula_eis-Briefpapier-1920.webp`"
          />
        </div>
        <div class="col-span-4">
          <Image
            class="w-full"
            :src="`${projectPath}elbworx-projekt-manula_eis-visitenkarte-960.webp`"
          />
        </div>
      </Grid>
    </Padding>

    <Padding class="mt-20 flex justify-center">
      <Text class="!max-w-5xl text-center">
        <p>
          Die kleine Eismanufaktur aus Pirna (Sachsen) versorgt sowohl Unternehmen als auch
          Privatpersonen. An schwülen Sommertagen war die stetige Versorgung mit Eisnachschub eine
          willkommene Abkühlung für heißlaufende Hirnzellen!
        </p>
      </Text>
    </Padding>

    <Tilt contain class="relative bg-[#FFCF00]">
      <Padding>
        <BackgroundMedia
          :background-image="`${projectPath}elbworx-projekt-manula_eis-ipad_hintergrund-3840.webp`"
        />
        <Device
          class="mx-auto my-10 max-w-xl"
          :element="{
            device_type: 'tablet',
            frame_color: 'black',
            image_url: `${projectPath}elbworx-projekt-manula_eis-startseite-1280.webp`,
            orientation: 'portrait',
          }"
        />
      </Padding>
    </Tilt>

    <Padding>
      <Grid class="items-start">
        <div class="col-span-5 md:col-end-7">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-manula_eis-ipad-1280-links.webp`"
          />
        </div>
        <div class="col-span-5 md:col-start-7">
          <Image
            class="w-full"
            :src="`${projectPath}elbworx-projekt-manula_eis-ipad-1280-rechts.webp`"
          />
        </div>
      </Grid>
    </Padding>

    <Padding class="flex justify-center">
      <div class="w-full max-w-xl">
        <Image
          class="h-80 w-full object-cover"
          :src="`${projectPath}elbworx-referenz-manula_eis-image-09.svg`"
        />
      </div>
    </Padding>
    <Padding class="flex justify-center">
      <div class="flex flex-wrap items-center justify-center gap-x-24 gap-y-12">
        <div>
          <Text class="mb-6">
            <h2 class="!text-[#B4968C]">Unsere Lieblingssorte:</h2>
          </Text>
          <div>
            <Image class="w-full" :src="`${projectPath}elbworx-projekt-manula_eis-image-07.svg`" />
          </div>
        </div>
        <div class="max-w-md">
          <Image class="w-full" :src="`${projectPath}elbworx-projekt-manula_eis-image-08.jpg`" />
        </div>
      </div>
    </Padding>
    <Padding>
      <Image
        class="h-full w-auto lg:-mt-20"
        :src="`${projectPath}elbworx-projekt-manula_eis-image-09.svg`"
      />
    </Padding>
  </Project>
</template>
